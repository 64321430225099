<template>
  <div>
    <router-view/>
      <div class="overlay" v-if="utility.showLoader">
        <div class="containerBox">
          <span class="circle"></span>
          <span class="circle"></span>
          <span class="circle"></span>
          <span class="circle"></span>
        </div>
        <div class="containerBox">
          <h4>
            {{utility.loadingMsg}}
          </h4>
        </div>
      </div>
  </div>
  </template>
  <style lang="scss" scoped>
  .overlay {
    position: fixed; /* Sit on top of the page content */
    display: block; /* Hidden by default */
    width: 100%; /* Full width (cover the whole page) */
    height: 100%; /* Full height (cover the whole page) */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5); /* Black background with opacity */
    z-index: 9999; /* Specify a stack order in case you're using a different order for other elements */
    cursor: wait; /* Add a pointer on hover */
    .pleaseWait{
      top: 44%;
      position: absolute;
      left: 40%;
      background: aliceblue;
      width: 24%;
      height: 70px;
      text-align: center;
      padding-top: 16px;
      border-radius: 7px;
    }
  }
  .containerBox{
    height: 15px;
    width: 105px;
    display: flex;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    margin: auto;
    .circle{
      width: 15px;
      height: 15px;
      border-radius: 50%;
      background-color:var(--iq-border-light);
      animation: move 500ms linear 0ms infinite;
      margin-right: 30px;
      &:first-child{
        position: absolute;
        top:0;
        left:0;
        animation: grow 500ms linear 0ms infinite;
      }
      &:last-child{
        position: absolute;
        top: 0;
        right: 0;
        margin-right: 0;
        animation: grow 500ms linear 0s infinite reverse;
      }
    }
    h4 {
      position: absolute;
      top: 27px;
      left: 13px;
      color: white !important;
      font-size: 13px !important;
    }
  }

  @keyframes grow {
    from {transform: scale(0,0); opacity: 0;}
    to {transform: scale(1,1); opacity: 1;}
  }

  @keyframes move {
    from {transform: translateX(0px)}
    to {transform: translateX(45px)}
  }
</style>
<script>
import { socialvue } from "./config/pluginInit"
import Utility from "./Utils/utility.js"

export default {
  name: "App",
  components: {
  },
  data () {
    return {
      utility: Utility
    }
  },
  mounted () {
    this.utility.showLoader = false
    socialvue.mainIndex()
  },
  methods: {
  }
}
</script>
  <style lang="scss">
    @import "assets/scss/style.scss";
    @import "assets_gide/css/style.scss";
    .table td, .table th{
      max-width: 140px;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 13px;
    }
  </style>
