/*eslint-disable */
// import orange from '../assets_gide/img/banners/Gide_background_banner_Orange.png'
// import green from '../assets_gide/img/banners/Gide_background_banner_Green.png'
import grey from '../assets_gide/img/banners/e5e5e5_grey.png'
// import red from '../assets_gide/img/banners/Gide_background_banner_Red.png'
import moment from 'moment'
import md5 from 'md5'
const { v4: uuidv4 } = require('uuid');

let Utility = {
  showLoader: false,
  loadingMsg: "",
  /**
   * getBgColorForLetter
   */
  getBgColorForLetter (self, letter) {
    if (letter) {
      let alphabets = 'abcdefghijklmnopqrstuvwxyz'
      let pos = alphabets.indexOf(letter.toLowerCase())
      if (pos < 6) {
        return 'primary'
      }
      else if (pos < 11) {
        return 'dark'
      }
      else if (pos < 16) {
        return 'warning'
      }
      else if (pos < 21) {
        return 'danger'
      }
      else if (pos < 27) {
        return 'info'
      }
      else {
        return 'primary'
      }
    }
  },
  /**
  * getBgImageForLetter
  */
   getBgImageForLetter (self, letter) {
    if (letter) {
      let alphabets = 'abcdefghijklmnopqrstuvwxyz'
      let pos = alphabets.indexOf(letter.charAt(0).toLowerCase())

      if (pos < 6) {
        return grey
      }
      else if (pos < 11) {
        return grey
      }
      else if (pos < 16) {
        return grey
      }
      else if (pos < 21) {
        return grey
      }
      else if (pos < 27) {
        return grey
      }
      else {
        return grey
      }
    }
  },
  /**
   * splitTextBy 
   * */
  splitTextBy(text, splitWith) {
    return text.split(splitWith)
  },
  /**
   * getYoutubeId 
  */
  getYoutubeId(url) {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);

    return (match && match[2].length === 11)
      ? match[2]
      : null;
  },
 /**
  * getFormatttedTimeAgo
  */
  getFormatttedTimeAgo (dateTime) {
    try {
      return moment.utc(dateTime).fromNow()
    }
    catch (err) {
      console.error("Exception occurred at  getFormatttedTimeAgo ()  and Exception:", err.message)
    }
  },
  /**
   * getDiffDateTime
   */
  getDiffDateTime (startDate, endDate) {
    if (startDate && endDate) {
      const end = moment(endDate)
      const start = moment(startDate)
      const duration = moment.duration(end.diff(start))
      const years = duration.years()
      const months = duration.months()
      const days = duration.days()
      const hr = duration.hours()
      const min = duration.minutes()
      return `${years ? years + " Years" : ""} ${months ? months + " Months" : ""} ${days ? days + " Days" : ""} ${hr ? hr + " Hrs" : ""} ${min ? min + " Min" : ""}`
    }
    else {
      return "0"
    }
  },
  /*
    * checkStrFirstCharHasAlphabet
    * */
  checkStrFirstCharHasAlphabet(str){
    if(/^[A-Za-z]/.test(str)) {
      return true;
    }
    return false;
  },

  /**
   * openUrlInNewTab
   */
  openUrlInNewTab (url) {
    window.open(url, "_blank")
  },
  /**
   * getUniqueId - While creating a Group, New Message or One-to-One Group
   */
   getUniqueId(user_id, some_id_or_data){
    let time = new Date().getTime();
    return md5(user_id+some_id_or_data+uuidv4()+time);
  },
  /*
    * get_user_icon
    * */
  get_user_icon(user, user_contact_list_lf){
    var user_icon = '';
    if(user_contact_list_lf 
      && user_contact_list_lf[user.user_id] 
      && user_contact_list_lf[user.user_id].user_image_base64
    ){
      user_icon = user_contact_list_lf[user.user_id].user_image_base64;
    }
    else if(user.image){
      user_icon = user.image;
    }
    else if(user.user_name && this.checkStrFirstCharHasAlphabet(user.user_name)){
      user_icon = null //require(`../assets/avatar/${user.user_name.charAt(0).toUpperCase()}.png`);
    }
    
    return user_icon;
  }
}

export default Utility
