/*eslint-disable*/
import Vue from 'vue'
import 'mutationobserver-shim'
import './Utils/fliter'
import App from './App.vue'
import VueGtag from "vue-gtag"
import router from './router'
import store from './store'
import Raphael from 'raphael/raphael'
import './plugins'
import './registerServiceWorker'
import i18n from './i18n'
import socketIo from 'socket.io-client'
import { StripePlugin } from '@vue-stripe/vue-stripe'
import moment from 'moment'
import infiniteScroll from 'vue-infinite-scroll'
import { VueEditor } from "vue2-editor"
import VueJsonToCsv from "vue-json-to-csv"
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import axios from 'axios'
import GAuth from 'vue-google-oauth2'

// initialise the google auth options on when the domain is gide.ai or the app environment is local
if(window.location.hostname.toLowerCase().includes("gide.ai") ||
   (process.env.VUE_APP_ENV != "prod" && process.env.VUE_APP_ENV != "uat")
 ) {
  const gauthOption = {
    clientId: '761559488538-iplndmv776in11d973nod6u4j2d8vu2f.apps.googleusercontent.com',
    scope: 'profile email',
    prompt: 'select_account'
  }
  Vue.use(GAuth, gauthOption)
}

Vue.use(infiniteScroll)
Vue.use(VueEditor)
Vue.component("VueJsonToCsv", VueJsonToCsv)
Vue.component("DateRangePicker", DateRangePicker)

if(process.env.VUE_APP_ENV == "prod"){
  Vue.use(VueGtag, {config: { id: "G-H5NB7C4BYF" }},router)
}
 
export const EventBus = new Vue();

Vue.prototype.moment = moment
const options = {
  pk: process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY,
  stripeAccount: process.env.VUE_APP_STRIPE_ACCOUNT,
  testMode: process.env.VUE_APP_STRIPE_TESTMODE,
  locale: process.env.VUE_APP_STRIPE_LOCALE,
};

Vue.use(StripePlugin, options);

window.addEventListener("flutterInAppWebViewPlatformReady", function (event) {
  console.log("flutterInAppWebViewPlatformReady NOW")
})

if (navigator.userAgent.toLowerCase().includes("gide_ios")) {
  window.__IS_APP__ = true
  window.__DEVICE_OS__ = "ios"
  window.__IS__MOBILE_DEVICE__ = true
}
else if(navigator.userAgent.toLowerCase().includes("gide_android")) {
  window.__IS_APP__ = true
  window.__DEVICE_OS__ = "android"
  window.__IS__MOBILE_DEVICE__ = true
}
else if(navigator.userAgent.toLowerCase().includes("iphone")
  || navigator.userAgent.toLowerCase().includes("ipod")
  || navigator.userAgent.toLowerCase().includes("ipad")
){
  window.__IS_APP__ = false
  window.__IS__MOBILE_DEVICE__ = true
  window.__DEVICE_OS__ = "ios"
}
else if(navigator.userAgent.toLowerCase().includes("android")) {
  window.__IS_APP__ = false
  window.__IS__MOBILE_DEVICE__ = true
  window.__DEVICE_OS__ = "android"  
}
else {
  window.__IS_APP__ = false  
  window.__IS__MOBILE_DEVICE__ = false
  window.__DEVICE_OS__ = "web"  
}

// Reload the APP if New Version is Deployed
if(process.env.VUE_APP_ENV == "prod" || process.env.VUE_APP_ENV == "uat") {
  window.onfocus = async function() {
    var VUE_APP_VERSION = localStorage.getItem("VUE_APP_VERSION")
    VUE_APP_VERSION = VUE_APP_VERSION ? VUE_APP_VERSION : process.env.VUE_APP_VERSION
  
    //Get the info from the Web Server
    axios({
      method: "get",
      url: process.env.VUE_APP_SERVER_URL + "/version",
      timeout: 1000 * 60 * 5, // Wait for 60*5 seconds
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })    
    .then((response) => {
      if(response.status === 200) {
        if (VUE_APP_VERSION !== response.data) {
          localStorage.setItem("VUE_APP_VERSION", response.data);
          window.location.reload(process.env.VUE_APP_SERVER_URL)
        }          
      }
    })
    .catch((e) => {
      console.error(e)
    })
  };
}

export var socket = socketIo( process.env.VUE_APP_GIDE_API, {transports: ['websocket'], upgrade: false, secure: true });

if(socket){
  console.log("New Socket Connected", socket)
  window.socket = socket
}

socket.on("connect_error", (err) => {
  console.log(`connect_error due to ${err}`)
});

socket.on("disconnect", function() {
  console.log("Disconnected")
});

socket.on("reconnect", function() {
  console.log("Reconnecting")
});

socket.on("connect", async function() {
  console.log("Connected After Reconnect")

  let user_id = null;
  try{
    let userData = await store.getters['User/userData'];
    user_id = userData.user_id
    socket.emit('subscribe_user_id', user_id, user_id);
  }
  catch(error){
    console.log("User Id Missing during socket reconnect")
  }
})

import socket_listener from "./Utils/socketListener";
Vue.prototype.$socketListener= new socket_listener(this, socket);

global.Raphael = Raphael

Vue.config.productionTip = false

// Authentication
router.beforeEach(async (to, from, next) => {
  let userData = store.getters['User/userData']

  // checking in query params if code/source is avaible then take the value and store in store
  // Eg: https://web.gide.ai?source=HSMCONNECTGGN
  if(to.query.source) {
    await store.commit("SourceTracker/setUserSource", to.query.source ? to.query.source : null)
  }

  //Handle the Request from server.js:apple_signin_webhook
  if(to.query && to.query.uri && to.query.uri.includes("apple_signin_webhook")) {
    if (!userData || (userData && !userData.user_is_logged_in)) {
      //Not logged in redirect to login page.
      let redirectUri = "/login";
      
      //APPLE LOGIN POPUP FAILED, SO LOGIN VIA REDIRECT
      if(to.query.f){
        redirectUri += "?f="+to.query.f;        
      }
      else if(decodeURIComponent(window.location.href.split("?")[1]).indexOf('uri=') !== -1){
        //Deep Link Handling for Not logged User. Redirect to the URL passed in "uri" Param 
        let decodedURI = decodeURIComponent(window.location.href.split("?")[1]);
        redirectUri = '/' + decodedURI.replace('uri=', "");//We dont need the string uri=
        console.log("Found Encoded URL. Not Logged In User, Redirect To => ", redirectUri);
      }
      
      next({
        path: redirectUri
      })
    }    
  }
  else if (to.matched.some(record => record.meta.requiresAuth) ) {
    // Check if User is Authenticated
    if (!userData || (userData && !userData.user_is_logged_in)) {
      // Not logged in redirect to login page.
      let redirectUri = '/login'
      await store.commit("DeepLink/setDeepLink", to.fullPath)

      next({
        path: redirectUri
      })
    }
    else if(to.meta.isAdmin) {
      if(userData.user_role === "USERROLE11111") {
        next()
      }
      else {
        next({
          path: '/home'
        })
      }
    }
    else {
      next()
    }
  } 
  else if (to.matched.some(record => record.meta.requiresVisitors)) {
    if (userData && userData.user_is_logged_in) {
      next({
        path: '/home'
      })
    }
    else {
      next()
    }
  } 
  else if (to.path === '/') {
    if (userData && !userData.user_is_logged_in) {
      next({
        path: '/home'
      })
    }
    else {
      next({
        path: '/login'
      })
    }
  } 
  else {
    // Store the endpoint that needs to be routed, once user is logged in
    // await store.commit("DeepLink/setDeepLink", to.fullPath)
      
    // TO be called for cases which does not need authentication
    next() // make sure to always call next()!
  }
})

let vm = new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')

window.vm = vm
